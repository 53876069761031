import { NgModule, inject, Injector, ErrorHandler } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthModule, AuthService } from '@auth0/auth0-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppInjector } from './app.injector';
import { AppComponent } from './app.component';

import { environment } from 'projects/mamezo/src/environments/environment';

import { TopPageModule } from './page/toppage/toppage.module';
import { MamezoAuthService } from './auth/mamezo-auth-service';

@NgModule({ declarations: [AppComponent],
    bootstrap: [AppComponent], imports: [FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        AppRoutingModule,
        TopPageModule,
        AuthModule.forRoot(environment.AUTH0_CONFIG)], providers: [
        ErrorHandler,
        {
            provide: AuthService,
            useClass: environment.DEV ? MamezoAuthService : AuthService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  injector: Injector = inject(Injector);

  constructor() {
    AppInjector.setInjector(this.injector);
  }
}
